<template>
  <div>
    <PageHeader :title="title">
      <div slot="buttons" class="tw-flex tw-flex-row tw-gap-2">
        <router-link
          :to="{ name: 'Shop' }"
          title="Shop"
          class="btn btn-primary"
        >
          <i class="fas fa-shopping-cart" />
          Shop
        </router-link>
        <router-link
          :to="{ name: 'ShopOrders' }"
          title="Bestellingen"
          class="btn btn-default"
        >
          <i class="fas fa-list" />
          Bestellingen
        </router-link>
      </div>
    </PageHeader>

    <div v-if="order" class="tw-my-8 tw-bg-white tw-rounded-md tw-shadow-md">
      <div class="tw-px-6 tw-py-4 tw-flex tw-flex-wrap tw-gap-2 tw-items-baseline tw-border-b-0.5">
        <a
          v-if="order.supplier"
          target="_blank"
          :href="`mailto:${order.supplier.email}`"
          class="tw-text-base tw-font-semibold tw-flex-grow"
        >
          {{ order.supplier.name }}
          <i class="tw-ml-1 fas fa-envelope" />
        </a>
        <span
          :class="['label', order.confirmed ? 'label-primary' : 'label-warning']"
        >
          {{ order.confirmed ? 'Bevestigd op ' : 'In Afwachting' }}
          {{ order.confirmed_on | day-hour }}
        </span>
        <span v-if="order.lines" class="tw-font-semibold">
          {{ order.lines.length }} {{ order.lines.length > 1 ? 'producten' : 'product' }}
        </span>
      </div>
      <div
        v-for="line in order.lines"
        :key="line.id"
        class="tw-px-6 tw-py-4 tw-flex tw-flex-wrap tw-justify-between tw-border-b-0.5"
      >
        <div>
          {{ line.product.category.name }}
          <h4 class="tw-my-2">{{ line.product.name }}</h4>
          <p class="tw-m-0">{{ line.product.description }}</p>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-text-right">
          <span class="tw-font-semibold">
            Aantal: {{ line.count }}
          </span>
          <a
            :href="line.product.design_file"
            target="_blank"
            title="Ontwerp"
            class="btn btn-xs btn-success"
          >
            <i class="fas fa-file-download" />
            Ontwerp
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import { getOrderDetails } from '@/services/shop'
import { errorModal } from '@/modalMessages'

export default {
  name: 'ShopOrderDetails',
  components: {
    PageHeader
  },
  data () {
    return {
      order: null
    }
  },
  computed: {
    orderId () {
      return this.$route.params.id
    },
    title () {
      return `Bestelling ${this.order?.reference || ''}`
    }
  },
  created () {
    this.fetchOrderDetails()
  },
  methods: {
    async fetchOrderDetails () {
      try {
        const response = await getOrderDetails(this.orderId)
        this.order = response.data
        return response
      } catch (error) {
        errorModal('Kan de bestelgegevens niet ophalen, probeer het opnieuw.')
      }
    }
  }
}
</script>
